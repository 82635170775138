.main-container-modulos{
  width: 100%;
  height: 100%;   
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url('../../assets/Imagens/3 (1).png');
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 2rem;
}

.modulos-header div{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100px;
  width: 350px;
  color: var(--cor1);
  font-weight: 400;

}

.modulos-header span{
  font-size: 18px;
  margin: 0.2rem 0;
}

.modulos-header{
  width: 100%;
  height: calc(100%/4);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.modulos-header img{
  margin-right: 5rem;
  width: 300px;
}

.modulos-cards{
  display: flex;
  flex-direction: row;    
  align-items: center;        
  width: 100%;
  min-width: 880px;    
  height: calc(100%/2);
  padding-left: 5rem;

}

.modulos-footer{
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: calc(100%/4);
  padding-right: 5rem;
}


.modulos-footer img{
  width: auto;
  height: 40px;
}

.modulos-footer img:hover{
  cursor: pointer;
}

/* Definição para diferente screem */
/* Estilos para telas menores, como tablets e smartphones */
@media (max-width: 768px) {
  /* Adicione seus estilos aqui */
  body {
    font-size: 16px;
  }
}

/* Estilos para telas médias, como notebooks */
@media (min-width: 769px) and (max-width: 1024px) {
  /* Adicione seus estilos aqui */
  html, body {
    font-size: 18px;    
  }
  
  body {
    /* zoom: 75%; */
    /* -moz-transform: scale(0.75); */
    /* -webkit-transform: scale(0.75); */
    /* transform: scale(0.75); */
  }

  .main-container-modulos{
    /* border: 1px solid red!important;    */
    display: flex; 
    align-items: center;
    justify-content: center;
    align-content: center;
    margin: 0!important;    
    width: 100vw!important;
    padding: 0!important;
  }

  .modulos-header{
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid black; */
    padding: 0!important;
    height: 100px;
    margin: 0!important;
    width: 100%!important;
  }

  .modulos-header img{    
    margin: 0;
  }

  .modulos-cards{
    /* border: 1px solid blue; */
    height: 100%!important;
    margin: 0!important;
    padding: 0!important;
    min-width: 769px!important;
    max-width: 1024px!important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .custom-modulo-card-main-container{
    /* border: 1px solid yellow;     */    
    margin: 0!important;
    padding: 5px!important;   

    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 240px!important;
    min-width: 160px!important;    
    height: 300px!important;
    width: 240px!important;
    padding: 10px 10px!important;
    background: rgba(247, 245, 245, 0.6);
    border-radius: 40px;
    margin: 0 0.6rem!important;
    
    
  }

  .custom-modulo-container-titulo-liberado, .custom-modulo-container-titulo-bloqueado{
    width: 100%;    
  }

  .custom-modulo-container-titulo-liberado h1, .custom-modulo-container-titulo-bloqueado h1{
    margin: 0;
    font-size: 20px!important;
    /* border: 1px solid; */
  }

  .custom-modulo-container-icon-back{    
    display: flex;
    align-items: center!important;
    
    
    margin: 0!important;
    padding: 0!important;
  }

  .custom-modulo-container-icon-back img{    
    margin: 0!important;
    padding: 0!important;
    width: 50px!important;
    height: 50px!important;
  }
  .modulos-footer{
    /* border: 1px solid; */
    height: 100px;
    margin: 0;
    padding: 0!important;
    display: flex;
    align-items: center!important;
    justify-content: flex-end;
    align-content: center;
    padding-right: 3rem!important;
  }

  .modulos-footer img{
    width: 25px;
    height: 25px;       
  }
  .custom-modulo-container-botao-bloqueado, .custom-modulo-container-botao-liberado{
    width: 180px;
    padding-bottom: 1rem;
  }



}

/* Estilos para telas maiores, como desktops */
@media (min-width: 1025px) {
  .main-container-modulos{
    /* border: 1px solid red!important;    */
    display: flex; 
    align-items: center;
    justify-content: center;
    align-content: center;
    margin: 0!important;    
    width: 100vw!important;
    padding: 0!important;
  }

  .modulos-header{
    padding: 0!important;    
    margin: 0!important;
    width: 100%!important;    
  }

  .modulos-cards{
    /* border: 1px solid blue; */
    height: 100%!important;
    margin: 0!important;
    padding: 0!important;
    min-width: auto!important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .custom-modulo-card-main-container{
    /* border: 1px solid yellow;         */
    margin: 0!important;
    padding: 5px!important;   
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 380px!important;
    min-width: 270px!important;    
    height: 370px!important;
    width: 270px!important;
    padding: 10px 20px!important;
    background: rgba(247, 245, 245, 0.6);
    border-radius: 40px;
    margin: 0 0.6rem!important;
  }
}

@media screen and (max-width: 1680px) {
  .modulos-footer {
    display: flex;
    align-items: flex-end;
    padding-bottom: 1rem;
  }

  .modulos-footer img{
    width: 40px;
    height: 40px;
  }
}
