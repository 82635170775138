.header-main-container{
  /* border: 1px solid black; */
  width: auto;
  height: 8vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;  
  background-color: var(--cor5);
  padding: 0 2.5rem;
}

.header-right{
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
}

.header-right div{  
  /* border: 1px solid red;   */
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header-icon-sino img{
  height: 50%;
  width: auto;
}

.header-icon-sino img:hover{
  cursor: pointer;
  height: 45px;
}

.header-icon-configuracoes img:hover{
  cursor: pointer;
  height: 45px;
}

.header-icon-sino{
  height: 100%;
  width: auto;  
}

.header-icon-configuracoes img{
  height: 50%;
  width: auto;
}

.header-icon-configuracoes, .header-icon-sino{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 60px;  
}

.header-left{
  height: 100%;
  display: flex;
  align-items: center;  
  
}

.header-left div{
  width: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header-left span{
  font-size: 12px;
  margin: 0.2rem 0;
  text-align: end;
  padding-right: 1.2rem;
}

.header-left img{
  height: 60%;
  width: auto;
}

.header-perfil:hover{
  cursor: pointer;
}

.header-perfil{
  display: flex;
  align-items: center;
  justify-content: center;

  width: auto;
  font-family: var(--fontFamily);
  font-size: var(--seectionHeader);
}

.header-left img:hover{
  cursor: pointer;
}