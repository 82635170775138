
.custom-toast-progress-success{
    background-color: var(--cor1)!important;
}

.custom-toast-success svg{
    fill: var(--cor1);
}

.custom-toast-progress-warning{
    background-color: var(--cor3)!important;
}

.custom-toast-warning svg{
    fill: var(--cor3);
}
