#file-uploadLabel, #file-upload:hover{
    cursor: pointer;
}

#file-upload{
    display: flex;
    justify-content: center;
    align-items: center;

    border: 2px dashed gray;
    border-radius: 8px;
    padding: 20px;
    font-size: 1.1rem;
    min-height: 150px;
    width: 100%;
}

#file-upload span{
    color: var(--label);
}

#file-upload svg{
    color: var(--label);
}

#file-uploadLabel{    
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;
}