.configuracoesItem{
    display: flex;
    flex-direction: column;
    width: 100%;  
}

.configuracoes-item{
    display: flex;
    flex-direction: column;
    align-items: center;        
    height: 58px;
    /* border: 1px solid; */
    padding: 10px 0;
}

.item{
    display: flex;
    width: 100%;
    height: 100%;
}

.item svg{
    margin: 0 10px;
    width: 25px;
    height: 25px;
    color: var(--fundo);
}

.item span{
    font-family: var(--fontFamilyRobotoMono);
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;

    letter-spacing: 0.5px;

    color: var(--fundo);
}

.configuracoes-item hr{
    width: 100%;
    color:  #E6EAED;
}

.configuracoes-item span:hover{
    color: var(--fundoHover);  
    font-size: 22px;
}

.configuracoesItem a{
    margin-top: 1rem;
}