.meu-perfil-Container{
    background-color: var(--cor5);
    width: 250px;

}

.meu-perfil-Container div{
    width: 100%;
    display: flex;    
}

.meu-perfil-close{
    height: 60px;
    justify-content: flex-end;    
    padding-right: 2rem;
    align-items: flex-end;        
}

.meu-perfil-close img{
    height: 25px;
    width: auto;    
}

.meu-perfil-close img:hover{
    cursor: pointer;
    height: 30px;
}

.meu-perfil-menu{ 
    align-items: center;
    justify-content: center;
    width: 80%;
}

.meu-perfil-avatar{    
    align-items: center;
    justify-content: center;
    height: 130px;
    margin: 1rem 0 2rem 0;
}
.meu-perfil-avatar img{
    height: 100%;
    width: 50%;
    border-radius: 50%;
}

.meu-perfil-menu ul li:hover{
    cursor: pointer;
    background-color: var(--cor3);
    border-radius: 30px ;
}

.meu-perfil-menu ul{    
    width: 90%;
    
}

.meu-perfil-menu ul li{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 45px;
    font-size: 18px;
}

.menuSelecionado{
    background-color: var(--cor3);
}