.aprovacoes-text{
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--cor5);
}


#dialog_aprovacoes_content{
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    background-color: #4e1975;
}

.p-dialog .p-dialog-header{
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    background-color: #4e1975;
}

.p-dialog{
    box-shadow: unset;
}


.detalhe{
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: var(--cor5);
}


.detalhe-download{
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: var(--cor5);
}

.documentos-download{
    /* border: 1px solid black; */
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: auto;
}

.documentos-download ul li{
    margin: 0.5rem;
}

.documentos-download ul li a{
    font-size: 18px;
    color: var(--cor5);    
}

.button-right{
    width: 100%;    
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    margin: 0.5rem 0;
}

.documentos-download ul li a:hover{
    color: #b39eae;
}

.aprovacoes-text-area{
    background-color: #4e1975;
    border-radius: 15px;
    resize: none;
    padding: 1rem;
    color: white;

    margin-bottom: 1rem;
}