.button-component-left{
    background-color: rgb(241, 240, 240, 0.8);
    color: var(--cor8);
    border: unset;
    border: 2px solid var(--cor1)!important;
    font-size: 15px;

    height: 40px;
    margin: 0 1rem;
    width: var(--tamanhoBotaoPadrao)!important;    
    font-family: 'Roboto', sans-serif!important;
}

.p-button.p-component.button-component-right{
    border-radius: 30px;
    margin: 0 0.5rem;
    background-color: var(--cor1);
    border: unset;
}

.p-button.p-component.button-component-right.info{
    border-radius: 30px;
    margin: 0 0.5rem;
    background-color: var(--cor10);
    border: unset;
}

.button-component-right, .button-component-right-cancelar{
    background-color: var(--cor1);
    color: var(--cor5);
    border: unset;
    font-size: 15px;

    height: 40px;
    margin: 0 1rem;
    width: var(--tamanhoBotaoPadrao)!important;
    border-radius: 30px;
    font-family: unset!important;
    font-family: 'Roboto mono', sans-serif!important;    
}

.button-component-left:hover{
    border: 3px solid var(--cor1)!important;
    background-color: unset!important;
    color: unset!important;
}

.p-button.p-component.button-component-right:hover{
    background-color: #826ACA;
}

.button-component-left span{
    font-weight: var(--fontweight);
    font-size: var(--fontSizeBtn);
}

.button-component-right span, .button-component-right-cancelar span{
    font-weight: var(--fontweight);
    font-size: var(--fontSizeBtn);
}


.button-component-search{
    background-color: unset;
    color: var(--cor8);    
    font-size: 15px;
    border: 2px solid var(--cor1);

    height: 40px;
    margin: 0 1rem;
    /* width: 250px; */
    border-radius: 30px;
    font-family: unset!important;    
    font-family: 'Roboto mono', sans-serif!important;        
}

.button-component-search span{
    font-weight: var(--fontweight);
    font-size: var(--fontSizeBtn);    
    text-align: flex-start;
}

.button-component-search div{
    width: 100%;
}

.button-component-search:hover{
    border: 2px solid var(--cor1)!important;
    background: none!important;    
    color: var(--cor8)!important;
}

.btn-icon-search{
    background-repeat: no-repeat;
    width: auto;
    height: 36px;
    transform: rotate(-45deg);
}

.btn-icon-search:hover{    
    width: auto;
    height: 40px;
}

.button-component-search input{
    height: 40px;
    width: 100%;
    border: unset;    
}

.button-component-right.info, .button-component-left.info{
    background-color: var(--cor3);
}

.p-button.p-component.button-component-right.info:hover{
    background-color: var(--cor3);    
}

.p-button.p-component.button-component-right-cancelar{
    background-color: unset;
    color: var(--cor5);
    border-radius: 30px;
    border: 1px solid var(--cor1);
}

.p-button.p-component.button-component-right-cancelar:hover{
    border: 2px solid var(--cor1);
}

.p-button.p-component.button-component-search{
    background-color: unset;
    border: 2px solid var(--cor1);
    border-radius: 30px;
    margin: 0 1rem;
}

.p-button.p-component.button-component-right.p-button-danger{
    background-color: red;
}