.data-Grid-Pro {
    background-color: white!important;
    border-radius: 10px;
}

.MuiDataGrid-columnHeaders{
    background-color: var(--cor2)!important;
}

.MuiDataGrid-columnSeparator.MuiDataGrid-columnSeparator--resizable.MuiDataGrid-columnSeparator--sideRight{
    color: var(--cor10)!important;
}

.MuiDataGrid-columnHeaderTitle{
    color: var(--cor5)!important;
    
    width: 100%;
    text-align: flex-start;
    text-align: center;
    justify-content: center;
}

.MuiDataGrid-columnHeaderTitleContainer{    
    width: 100%;
}

.MuiDataGrid-columnHeaderDraggableContainer{    
    width: 100%;
}

.MuiDataGrid-columnHeaderTitleContainerContent{
    width: 100%;
}


.MuiDataGrid-cellContent{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
}

.MuiDataGrid-columnHeader:hover{
    background-color: transparent;
}


.MuiDataGrid-cellContent  {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
}