.action-button.export-button{
    background-color: var(--cor10);
}

.action-button.export-button:hover{
    background-color: var(--cor3);
}

.action-button.import-button{
    background-color: var(--cor1);
}


.action-button.import-button:hover{
    background-color: var(--fundoHover);
}

.action-button.import-button, .action-button.export-button{
    margin: 0.5rem;
    width: 170px;
    border-radius: 25px;
    color: #f8f9fa;
    height: 40px;
}

#action-button-upload{
    background-color: var(--cor1);
    border-radius: 25px;
    height: 40px;
    width: auto;    
}

#action-button-upload:hover{
    background-color: var(--fundoHover);
}

#action-upload{
    display: flex;
    align-items: center;
    align-content: center;
    text-align: center;
    background-color: unset;
    color: pink;

    border-radius: 35px;

}