.custom-modulo-card-main-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 350px;
    min-width: 270px;
    height: 400px;
    padding: 40px 32px;
    background: rgba(247, 245, 245, 0.6);
    border-radius: 40px;
    margin: 0 0.6rem;
}

.custom-modulo-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    font-family: var( --inputFontFamily);
    width: 100%;
    /* border: 1px solid black; */
}

.custom-modulo-container-icon, .custom-modulo-container-icon-back{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 25%;
    /* border: 1px solid red; */
}

.custom-modulo-container-icon-back {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 25%;
    flex-direction: row-reverse;
}

.custom-modulo-container-icon, .custom-modulo-container-icon-back img{
    margin: 80px 0 0px 0;
    width: auto;
    height: 80px;
}

img.icon-right{
    width: 50px;
    height: 50px;
    color: black;
}

.custom-modulo-container-titulo{
    width: 100%;
    height: 15%;
    /* max-width: 360px; */
    min-width: 356px;
}

.custom-modulo-container-titulo-bloqueado h1{
    font-weight: 500;
    font-size: xx-large;
    color: var(--cor7);    
    text-align: center;
}

.custom-modulo-container-titulo-liberado h1{
    font-weight: 500;
    font-size: xx-large;
    display: flex;
    text-align: center;
}


.custom-modulo-container-botao-bloqueado, .custom-modulo-container-botao-liberado{
    display: flex;
    justify-content: center;
    align-items: center;    
    height: 20%;
}

.custom-modulo-container-botao-bloqueado button{
    background-color: var(--cor7)!important;
    border-radius: 10rem;
    border: none;
    width: var(--tamanhoBotaoPadrao); 
    justify-content: center;
}

.custom-modulo-container-botao-liberado button{
    background-color: var(--cor1);
    border-radius: 10rem;
    border: none;
    width: var(--tamanhoBotaoPadrao);    
    justify-content: center;
}

.custom-modulo-container-botao button:hover{
    background-color: var(--cor2)!important;
}

/*FLIPER CARD CSSS*/
.flip-container, .flip-container0, .flip-container1, .flip-container2, .flip-container3, .flip-container4 {
    width: 350px;
    height: 200px;
    perspective: 1000px;
    transition: transform 0.8s;
    transform-style: preserve-3d;    
}
  
 .flipper {
    position: relative;
      width: 100%;
      height: 100%;
      transition: transform 0.8s;
      transform-style: preserve-3d;
 }
  
 /* .flip-container:hover .flipper{
      transform: rotateY(180deg);
 } */
  

 .flip-container0, .flip-container1, .flip-container2, .flip-container3,  .flip-container4 .flipper{
    transform: rotateY(180deg);
    transition: transform 0.8s;
    transform-style: preserve-3d;
}


 .front, .back{
    position: absolute;
    width: 100%;
    height: 100%;    
 }

 .icon-right:hover{
    cursor: pointer;
 }