* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', monospace;    
}

*:focus {
  outline: 0;
}

html,
body,
#root {
  height: 100%;
}

:root {

    /* Definição das cores-------------------*/
    --main-background: #EAEAEA;
    --grey: #A6A6A6;
    --blue: #5499e9;
    --blueHover: #2a79d9;
    --white: #ffff;
    --textColor: rgba(255, 255, 255, 0.7);
    --textColorTitlePage: #003F89;
    --backgroundBtn: #89B8EF;
    --backgroundLabel: #f8f9fa;
    
  
    /* Definição dos Inputs------------------*/
    --inputFontFamily: 'Roboto';
    --inputFontStyle: normal;
    --inputTextFontSize: 12px;
    --inputLineHeight: 14px;
    --inputFontWeight: 400;
    --inputTextColor: #A6A6A6;
    --inputAndButtonHeight: 41px;
  
    /*Cores padrão do sistema*/
    --fundo: #ac59ff;
    --label: #ac59ff;
    --fundoHover: #984de2;
    --buttonColorNovo: #984de2;
    --buttonColorExcluir: red;
    --fontSeziHeader: 20px;
    --heightHeader: 60px;
    --marginMenuHeader: 10px 0 10px 0;
    --sidebarLogoHeader: 40px;
    --buttonColor: #ac59ff;
    --buttonColorHover: #984de2;
    --fontColor: #000000;
    --fontFamilyRobotoMono: 'Roboto Mono', monospace;
    --backgroundButton: rgba(247, 245, 245, 1);
    --backgroudHeader: rgb(78, 25, 117,0.7);
    --backgroundSideBar: #4E1975;

    /*Sistema - Paletas de cores*/
    --cor1: #ac59ff;
    --cor2: #4e1975;
    --cor3: #8bb5e0;
    --cor4: #fbfbfb;
    --cor5: #eeeeee;
    --cor6: #dddddd;
    --cor7: #666666;
    --cor8: #000000;
    --cor10: #3c90e3;

    /*Sistema - Fontes*/
    --fontFamily: 'Roboto', monospace;
    --titulo: 40px;
    --subtitulo: 20px;
    --header: 18px;
    --subheading: 14px;
    --seectionHeader: 18px;
    --body: 16px;
    --quote: 16px;
    --aceiteSize: 10px;
    --fontweight: 400;
    --fontSizeBtn: large;
    --fontSizeLabel: 18px;

    /*Sistema - Buttons*/
    --tamanhoBotaoPadrao: 170px;
    --tamanhoListaPadrao: 250px;
    --tamanhoCampoTextoPadrao: 250px;
    --alturaCampoTextoPadrao: 45px;
}

body {
  font: 18px sans-serif;
  background-color: var(--main-background);
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

button {
  cursor: pointer;
}

.p-inputtext:enabled:focus{
  box-shadow: none!important;
  border-bottom: 1px solid var(--cor7)!important;
}

.p-paginator .p-paginator-pages .p-paginator-page {
  width: auto;
}

.p-inputtext:hover{
  border-color: var(--cor1)!important;
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  width: auto;
}

#outlined-name {
  width: 28rem;
}

#outlined-email {
  width: 28rem;
}

#outlined-select-currency {
  width: 26.75rem;
}

.p-toolbar {
  border-radius: 10px !important;
  background-color: white !important;
  background: #f8f9fa !important;
  border: 1px solid #dee2e6 !important;
  padding: 0px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  border-radius: 6px !important;
  padding-top: 10px!important;
  padding-bottom: 10px!important;
}

.p-datatable .p-paginator-bottom {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.p-datatable .p-datatable-header {
  background: white !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}