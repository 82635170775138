.main-container-login{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-image: url('../../assets/Modulos/LegalInsights/signIn-background.png');
    /* background-image: linear-gradient(180deg, #8bb5e0, #ac59ff); */
    background-repeat: no-repeat;
    background-size: cover;            
    background-position: center;
}

.container-login{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* border: 1px solid black; */
    width: 100%;
    height: 100%;
}

.container-login-footer{
    display: flex;
    align-items: center;
    justify-content: flex-end;    
    width: 100%;    
    padding-right: 250px;
    
}

.container-login-footer img{
    width: 267px;
    margin-top: -150px;
}

.form-login{
    display: flex;
    flex-direction: column;
    align-items: center;      
    width: 500px;
    height: auto;
    padding: 40px 50px;   
    background: rgba(247, 245, 245, 0.3);
    border-radius: 40px;    
}

.form-login-style-title{
    height: 56px;    
    font-style: normal;
    font-weight: 400;
    font-size: var(--titulo);
    line-height: 56px;    
    color: var(--fontColor);
    flex: none;
    order: 0;
    flex-grow: 0;
    width: 100%;
    margin-bottom: 32px;     
}

.form-login-style-title:hover{
    cursor: unset;
}

#form-login-email{
    /* Elements/Input */
    box-sizing: border-box;
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    /* gap: 10px; */
    width: 100%;
    height: var(--alturaCampoTextoPadrao);
    /* Composition/Background */
    background: rgba(247, 245, 245, 1);
    /* Institutional/Blue/Dark */
    border: 1px solid rgba(247, 245, 245, 1)!important;
    border-radius: 50px;
    /* Inside auto layout */
    /* flex: none; */
    /* order: 0; */
    /* flex-grow: 0; */
    margin: 0 0 15px 0;
    font-family: var(--fontFamilyRobotoMono)!important;
    font-size: 16px;
}

#form-login-password input{
    /* Elements/Input */
    box-sizing: border-box;
    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    gap: 10px;
    width: 400px;
    height: 56px;

    /* background: #FAFAFA; */
    border: 1px solid rgba(247, 245, 245, 1)!important;
    border-radius: 50px;

    flex: none;
    order: 0;
    flex-grow: 0;
    
}

.form-login-esqueci-senha{
    display: flex;
    flex-direction: column;
    justify-content: center!important;
    align-items: center;
    height: auto;    
    width: 100%;
    padding: 0;
}

.form-login-esqueci-senha div{
    margin-top: 10px;
}

.form-login-esqueci-senha a{
    font-family: var(--fontFamilyRobotoMono)!important;
    font-style: normal!important;
    font-weight: 400!important;
    font-size: 16px!important;
    line-height: 19px!important;
    /* identical to box height */
    /* Institutional/Blue/Dark */
    color: var(--fontColor)!important;
    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
}


.form-login-entrar{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;    
    margin-top: 32px;
    /* border: 1px solid blue; */
}

.form-login-entrar button{
    display: flex;
    /* Auto layout */
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px 0px;

    width: var(--tamanhoBotaoPadrao);
    height: 45px;

    /* Institutional/Blue/Dark */

    background: var(--cor1);
    border-radius: 50px;
    border: unset;

    font-family: var(--fontFamily);
    font-style: normal;
    font-weight: 100;
    font-size: 14,3;
    line-height: 16px;
    
    letter-spacing: 1.25px;
    text-transform: uppercase;
    
    /* Composition/Background */
    
    color: var(--fontColor);
}

.form-login-entrar button:hover{
    background: var(--cor2)!important;
    color: var(--cor5)!important;
}



.wrap-password{
    display: flex;
    justify-content: center;
    align-items: center;    
    min-width: 276px;
    /* font-style: normal; */
    color: #A6A6A6;
    justify-content: space-between;    
    padding: 10px;
    height: var(--auturaCampoTextoPadrao);
    /* width: 350px; */
    background: var(--backgroundButton);
    border: 1px solid rgba(247, 245, 245, 1)!important;
    border-radius: 50px;
    font-family: var(--fontFamilyRobotoMono)!important;
    font-weight: 400!important;
    font-size: 16px;
}

.wrap-password input{
    border: unset;   
    box-sizing: border-box;    
    display: flex;
    flex-direction: row;
    align-items: center;
    background: var(--backgroundButton)!important;      
    width: 100%;
    height: 100%;
    font-family: var(--fontFamilyRobotoMono)!important;
    font-weight: 400!important;
    font-size: 16px;
}

#linha-signin{
    background-color: var(--cor1);    
    height: 10px;
    width: 90px;
    border: unset;
}

.form-login-inputs{
    /* border: 1px solid red; */
    width: 100%;
}

.primeiro-acesso{
    /* border: 1px solid black; */
    /* margin-top: 0.5rem; */
    display: flex;    
    flex-direction: column;
    padding: 1rem 0;
}
.primeiro-acesso span{
    margin: 0.5rem;
}

.primeiro-acesso input{
    border: unset;   
    box-sizing: border-box;    
    display: flex;
    flex-direction: row;
    align-items: center;
    background: var(--backgroundButton)!important;      
    width: 100%;
    height: 56px;
    font-family: var(--fontFamilyRobotoMono)!important;
    font-weight: 400!important;
    font-size: 16px;
    margin: 0.5rem 0;
    border-radius: 30px;
    padding: 0 1rem;
}


.form-login-entrar-primeiro-acesso{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;     
    margin-top: 1rem;
}

.form-login-entrar-primeiro-acesso button{
    display: flex;
    /* Auto layout */
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px 0px;

    width: 100%;
    height: 40px;

    /* Institutional/Blue/Dark */

    background: var(--cor1);
    border-radius: 50px;
    border: unset;

    font-family: var(--fontFamily);
    font-style: normal;
    font-weight: 100;
    font-size: 14,3;
    line-height: 16px;
    
    letter-spacing: 1.25px;
    text-transform: uppercase;
    
    /* Composition/Background */
    
    color: var(--fontColor);
}

.form-login-entrar-primeiro-acesso button:hover{
    background: var(--cor2)!important;
    color: var(--cor5)!important;
}

.info-nova-senha{
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 3px solid var(--cor1);
    padding: 0.6rem;
    word-spacing: 4px;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgb(172, 89, 255);
    margin-bottom: 0.5rem;
    height: 70px;
}

.info-nova-senha p{
    font-family: var(--fontFamily);
    font-size: 15px;
}

.login-footer-icon-whatsapp{
    padding-right: 3rem;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.login-footer-icon-whatsapp svg:hover{
    cursor: pointer;
    height: 55px;
    width: 55px;

    color: #21aa53!important;
}

.login-footer-icon-whatsapp div{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
}