.menu-configuracoes-Container{
    background-color: var(--cor5);
    width: 250px;    
    height: fit-content;    
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem 0 1rem 0;
}

.menu-configuracoes-Container ul{
    width: 90%;
    display: flex;
    flex-direction: column;
}

.menu-configuracoes-Container ul li{
    height: 45px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
}

.menu-configuracoes-Container ul li:hover{
    cursor: pointer;
    background-color: var(--cor3);
    border-radius: 30px;
    height: 45px;
}