.footer-password-info{    
    width: 350px;
}

.footer-password-info ul li{
    font-size: small;
}

.textfiled-password-component input{
    background-color: var(--cor5);
}

