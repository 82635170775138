.main-container-cadastrar-se{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-image: url('../../assets/Modulos/LegalInsights/signIn-background.png');
    background-repeat: no-repeat;
    background-size: cover;            
    background-position: center;
    /* border: 1px solid black; */
}

.container-cadastrar-se{
    display: flex;
    /* border: 1px solid black; */
    justify-content: space-between;
    align-items: center;
    height: 250px;
    margin: 100px 100px 0 100px;
}

.cadastrar-se-title{
    display: flex;
    /* border: 1px solid black; */
    margin: 0px 100px;
    font-family: 'Poppins', sans-serif;    
}

.cadastrar-se-title h1{
    color: white;
    font-weight: 500;
    font-size: 2.35rem;
    margin: 0;

}
.cadastrar-se-juridico-fisico{
    display: flex;
    /* border: 1px solid black; */
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: 0 50px;    
}

.cadastrar-se-juridico-fisico h1{
    margin: 0 50px;
    font-family: 'Poppins', sans-serif;
    
}

.cadastrar-se-logo{
    display: flex;
    width: 250px;    
}

.cadastrar-se-logo img{
    width: 100%;
}

.cadastrar-se-header{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-family: 'Poppins', sans-serif;
}

.cadastrar-se-header h1{
    color: white;
    font-size: 2.35rem;
    font-weight: 500;
    word-spacing: 0.5rem;
    margin: 0;
}


