.menu-lateral-container{
    background-color: var(--cor5);
    width: 250px;
    height: 92vh;
    padding-top: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.menu-lateral-container ul{    
    width: 90%; 
}


.menu-lateral-container ul div{
    width: 100%;
    height: 60px;
    width: 100%;
    height: 60px;
    display: flex; 
    align-items: center;
}

.menu-lateral-container ul div li{
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    height: 45px;
    width: 100%;    
    padding-left: 2.5rem;
}

.menu-lateral-container ul div li img{
    height: 25px;
    margin: 0 0.5rem 0 0;
}

.menu-lateral-container ul li:hover{
    cursor: pointer;
    background-color: var(--cor3);    
    border-radius: 30px;
}

.menuSelecionado{
    background-color: var(--cor3);
    border-radius: 30px;
}

.subMenu-StatusReports{    
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    text-align: center;
    height: 45px;
    width: 80%;
    margin-left: 2.5rem;
    padding-left: 2.5rem;
}


.menu-lateral-footer{
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;    

    margin-bottom: 20px;
}

.menu-lateral-footer span{
    font-size: 10px;
    text-align: center;
}