.dialog-show-modal-Cadastro-novo-usuario.p-dialog-content{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2rem;
    padding-bottom: 0;
}

.dialog-show-modal-cadastro-novo-usuario.footer{
    display: flex;
    justify-content: center;
    align-items: center;
}

.dialog-show-modal-cadastro-novo-usuario-em-lote.footer{
    display: flex;
    justify-content: center;
    align-items: center;
}

.dialog-show-modal-cadastro-novo-usuario-em-lote.conteudo{

    width: 100%;    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 1.5rem;
}

.dialog-show-modal-cadastro-novo-usuario-em-lote.conteudo a{
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;    
}

.show-modal-cadastro-novo-usuario-em-lote .p-dialog-content{
    padding: 0;
}

.dialog-show-modal-cadastro-novo-usuario-unico.form{    
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 1rem;    
}

.dialog-show-modal-cadastro-novo-usuario-unico.form input{
    margin: 0.5rem;
}

.dialog-show-modal-cadastro-novo-usuario-unico.form div{
    margin: 0.5rem;
}

.dialog-show-modal-cadastro-novo-usuario-unico.form-checkbox{
    width: 100%;
    padding: 0 5rem;
    height: 40px;    

    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    margin-top: 0;
}

.p-dropdown-panel{
    max-width: 360px;
}