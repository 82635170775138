.custom-card-main-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 500px;
    height: 430px;
    padding: 40px 32px;
    background: rgba(247, 245, 245, 0.4);
    border-radius: 40px;

    margin: 0 100px;
}

.custom-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-family: var( --inputFontFamily);
    width: 100%;
}

.custom-container-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50%;
}

.custom-container-icon img{
    margin: 30px 0 20px 0;
    width: 100px;
    height: 100px;
}

.custom-container-titulo{
    width: 100%;
    height: 15%;
    /* max-width: 360px; */
    min-width: 356px;
}

.custom-container-titulo h1{
    font-weight: 600;
    font-size: xx-large;
}

.custom-container-descricao{
    display: flex;
    justify-content: center;    
    align-items: center;
    text-align: center;    
    width: 100%;
    height: 25%;

    font-family: 'Roboto mono';

}

.custom-container-descricao span{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    word-spacing: 0.5rem;    
    width: 300px;
    color: #545454;
    height: 100%;
    
}

.custom-container-botao{
    display: flex;
    justify-content: center;
    align-items: center;    
    height: 20%;
}

.custom-container-botao button{
    background-color: var(--cor1);
    border-radius: 10rem;
    border: none;
    width: 250px;    
    justify-content: center;
}

.custom-container-botao button:hover{
    background-color: var(--cor2)!important;
}