
.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
    border-radius: 30px!important;
    border: 1px solid var(--cor1);
}


#textfile_select_labels_id{
    border-radius: 30px;
    width: 200px;
    margin: 0 1rem;
}

.css-1rv3ei8-MuiFormControl-root-MuiTextField-root{
    margin: 0 1rem!important;
}

.form_select_labels{    
    width: 200px;
    margin: 0 1rem;
}

.form_select_labels select{
    width: 100%;
    border-radius: 30px;
    border: 1px solid var(--cor1);
}

.form_select_labels select:hover{
    cursor: pointer;
    border: 2px solid var(--cor1) ;
}

.form_select_labels select option{
    width: 100%;
    border: 1px solid red;
}

select#select_options option{
    border: 3px solid red;
}

#select_options option{
    border: 3px solid red!important;
}

.card{
    width: auto;
    margin: 0 0.5rem;
}

.w-full{
    border: 2px solid var(--cor1);
    border-radius: 30px;  
    height: 40px; 
}

.w-full:hover{
    border: 3px solid var(--cor1)!important;
}

.p-dropdown .p-dropdown-label{
    display: flex;
    align-content: center;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
}