.conteudo-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
}

.loadProcessos{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80vh;
}
