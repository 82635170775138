.meus-processos-filtros{    
    height: 100px;
    width: 100%;
    display: flex;
    align-items: flex-end;
    padding-left: 2rem;
    padding-bottom: 1rem; 
}

.renderCell-icons{
    width: 100%;
    /* text-align: center; */
}

.renderCell-icons img{
    width: 25px;
    margin: 0 0.5rem;    
}

.renderCell-icons img:hover{
    cursor: pointer;
}

.renderCell-icons.detalhe:hover{
    cursor: pointer;    
    font-size: large;
}


.renderCell-icons.detalhe{
    color: var(--cor3);
}


.checklist-detalhe p:hover{
    cursor: pointer;
}