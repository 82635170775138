.modulo-projeto-acordos-container{
    height: 100vh;
    width: 100vw;
    background-color: var(--cor4);
}

.projeto-acordo-container{
    height: auto;
    display: flex;
    flex-direction: row;
}

.projeto-acordo-menu-right{
    display: flex;
    align-content: flex-end;
    width: 250px;
    background-color: var(--cor5);
}

.projeto-acordo-menu-left{
    width: 250px;
    height: 92vh;
}

.main-container-conteudo{
    width: calc(100vw - 250px);
    margin: 0;
    padding: 0;
}

.main-container-conteudo-open{
    width: calc(100vw - 500px);
    margin: 0;
    padding: 0;
}

.box-header-arquivos{
    margin-top: 1rem;
    width: 100%;
}

#app-bar-header-arquivos{
    height: 100px;
    border-radius: 6px;
    background-color: #f8f9fa;
}

.toolbar-header-arquivos{
     height: 100%;
}

#demo-simple-select-label{
    background-color: #f8f9fa;
    width: 80px;
    padding: 0 10px;
}

#toolbar-header-arquivos {    
    display: flex;
    justify-content: space-between;
}

#dialog-title{
    display: flex;
    height: auto;
    align-items: center;
    justify-content: center;
    color: var(--cor2);
    font-size: xx-large;
}


#dialog-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 200px;
}

#dialog-content-text{
    display: flex;
    flex-direction: column;
    width: 100%;    
    justify-content: center;
    align-items: center;
    height: 100%;
}

#dialog-content-text svg{
    color: var(--cor1);
    width: auto;
    height: 40px;
}

#dialog-content-text svg:hover{
    color: var(--cor1);
}

#dialog-content .input-file-name{
    width: 250px;
    height: 45px;
    border: unset;
    margin-left: 1rem;
    background-color: var(--cor5);
}

.div-escolher-arquivo{
    margin-top: 1rem;
}

.download-arquivos{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.download-arquivos svg{
    
    color: var(--buttonColor);;
}

.download-arquivos svg:hover{
    cursor: pointer;
    width: 30px;
    height: 30px;
}