.container-alterar-senha{
    display: flex;    
    height: 92vh;
    width: 100%;    

    flex-direction: column;
    align-items: center;
}

.container-alterar-senha div{
    width: 400px;        
    margin: 0.5rem 0;
}

.container-alterar-senha div input{
    width: 100%;
}

.alterar-senha-salvar{
    display: flex;    
    justify-content: flex-end;
    align-items: flex-end;
    align-content: flex-end;    
    margin: unset;
    height: 80px;
}

.container-alterar-senha div button{
    width: 200px;     
    margin: unset;
    color: var(--cor4);
}

.container-alterar-senha .titulo-alterar-senha{
    /* border:1px solid red; */
    width: 100%;
    padding-left: 30%;
    height: 100px;
    display: flex;
    align-items: flex-end;
}