.modulo-deposito-judicial-container{
    height: 100vh;
    width: 100vw;
    background-color: var(--cor4);
}

.deposito-judicial-container{
    height: auto;
    display: flex;
    flex-direction: row;
}

.deposito-judicial-menu-right{
    display: flex;
    align-content: flex-end;
    width: 250px;
    background-color: var(--cor5);
}

.deposito-judicial-menu-left{
    width: 250px;
    height: 92vh;
}

.main-container-conteudo{
    width: calc(100vw - 250px);
    margin: 0;
    padding: 0;
}

.main-container-conteudo-open{
    width: calc(100vw - 500px);
    margin: 0;
    padding: 0;
}

